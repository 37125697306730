import Work1 from "../../assets/trabalho1.jpg";
import Work2 from "../../assets/trabalho2.jpg";
import Work3 from "../../assets/trabalho3.jpg";
import Work4 from "../../assets/trabalho4.jpg";
import Work5 from "../../assets/trabalho5.jpg";
import Work6 from "../../assets/work5.jpg";

export const projectsData = [
  {
    id: 1,
    image: Work1,
    title: "LP Planejados",
    category: "web",
    link: "https://lpplanejados.com.br/",
  },
  {
    id: 2,
    image: Work2,
    title: "Artesanato In Casa",
    category: "web",
    link: "https://artesanatoincasa.com.br/loja/",
  },
  {
    id: 3,
    image: Work3,
    title: "Planeta Mulheres Sustentáveis",
    category: "web",
    link: "https://mulheressustentaveis.eco.br/",
  },
  {
    id: 4,
    image: Work4,
    title: "Intranet para Empresas",
    category: "web",
    link: "#",
  },
  {
    id: 5,
    image: Work5,
    title: "E-commerce Belchior (TCC)",
    category: "web",
    link: "https://github.com/lucascwtch/belchior",
  },
  {
    id: 6,
    image: Work6,
    title: "Robô para o Discord",
    category: "app",
    link: "https://github.com/lucascwtch/boteas",
  },
];

export const projectsNav = [
  {
    name: 'all',
  },
  {
    name: 'web',
  },
  {
    name: 'app',
  },
]
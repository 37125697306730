import React from 'react';
import "./footer.css";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__container container">
                <h1 className="footer__title">
                    lucas
                </h1>
                <ul className="footer__list">
                    <li>
                        <a href="#about" className="footer__link">Sobre</a>
                    </li>
                    <li>
                        <a href="#portfolio" className="footer__link">Projetos</a>
                    </li>
                    <li>
                        <a href="#testimonials" className="footer__link">Depoimentos</a>
                    </li>
                </ul>

                <div className="footer__social">
                    <a href="https://www.instagram.com/cwtch.lucas/" className="footer__social-link" target="_blank" rel="noopener noreferrer">
                        <i class="uil uil-instagram"></i>
                    </a>

                    <a href="https://github.com/lucascwtch" className="footer__social-link" target="_blank" rel="noopener noreferrer">
                        <i class="uil uil-github-alt"></i>
                    </a>

                    <a href="https://www.linkedin.com/in/lucas-ac/" className="footer__social-link" target="_blank" rel="noopener noreferrer">
                        <i class="uil uil-linkedin"></i>
                    </a>
                </div>
                <span className="footer__copy">
                    &#169; Lucas Almeida. All rights reserved
                </span>
            </div>
        </footer>
    )
}

export default Footer
import Image1 from "../../assets/testimonial1.png";
import Image2 from "../../assets/testimonial2.png";
import Image3 from "../../assets/testimonial3.png";

export const Data = [
    {
        id: 1,
        image: Image1,
        title: "Tecno Masters",
        description:
            "Excelente desenvolvedor! A entrega foi rápida e eficiente, e o projeto atendeu todas as nossas necessidades. Estamos muito satisfeitos.",
    },
    {
        id: 2,
        image: Image2,
        title: "LP Planejados",
        description:
            "Trabalho excepcional! Cada etapa do projeto foi bem conduzida, resultando em um produto final que superou nossas expectativas.",
    },
    {
        id: 3,
        image: Image3,
        title: "Planeta Mulheres Sustentáveis",
        description:
            "Ótima experiência de colaboração. O projeto foi entregue no prazo e com uma qualidade impressionante. Recomendo fortemente seus serviços.",
    },
];
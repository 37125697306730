import React, { useState } from 'react';
import "./services.css";

const Services = () => {
    const [toggleState, setToggleState] = useState(0);

    const toggleTab = (index) => {
        setToggleState(index);
    }

    return (
        <section className="services section" id="services">
            <h2 className="section__title">Serviços</h2>
            <span className="section__subtitle">O que eu posso oferecer?</span>

            <div className="services__container container grid">

                <div className="services__content">
                    <div>
                        <i className="uil uil-web-grid services__icon"></i>
                        <h3 className="services__title">Automação de<br /> Processos </h3>
                    </div>

                    <span className="services__button" onClick={() => toggleTab(1)}>
                        Ver Mais
                        <i className="uil uil-arrow-right services__button-icon"></i>
                    </span>

                    <div className={toggleState === 1 ? "services__modal active-modal" : "services__modal"}>
                        <div className="services__modal-content">
                            <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>
                            <h3 className="services__modal-title">
                                Automação de Processos</h3>
                            <p className="services__modal-description">Transforme tarefas repetitivas em processos automatizados, aumentando a eficiência e reduzindo erros.</p>

                            <ul className="services__modal-services grid">
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon">
                                    </i>
                                    <p className="services__modal-info">Identificação de oportunidades de automação.
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon">
                                    </i>
                                    <p className="services__modal-info">Desenvolvimento de scripts automatizados.
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon">
                                    </i>
                                    <p className="services__modal-info">Integração de sistemas.
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon">
                                    </i>
                                    <p className="services__modal-info">Monitoramento e manutenção.
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon">
                                    </i>
                                    <p className="services__modal-info">Suporte e orientação para uso das automações.

                                    </p>
                                </li>

                            </ul>

                        </div>
                    </div>
                </div>

                <div className="services__content">
                    <div>
                        <i className="uil uil-arrow services__icon"></i>
                        <h3 className="services__title">Desenvolvedor <br /> Frontend</h3>
                    </div>

                    <span className="services__button" onClick={() => toggleTab(2)}>
                        Ver Mais
                        <i className="uil uil-arrow-right services__button-icon"></i>
                    </span>

                    <div className={toggleState === 2 ? "services__modal active-modal" : "services__modal"}>
                        <div className="services__modal-content">
                            <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>
                            <h3 className="services__modal-title">Desenvolvedor Frontend</h3>
                            <p className="services__modal-description">Interfaces de usuário modernas e responsivas que proporcionam uma experiência excelente aos usuários.</p>

                            <ul className="services__modal-services grid">
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon">
                                    </i>
                                    <p className="services__modal-info">Desenvolvimento de interfaces de usuário.
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon">
                                    </i>
                                    <p className="services__modal-info">Design responsivo.
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon">
                                    </i>
                                    <p className="services__modal-info">Otimização de performance.
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon">
                                    </i>
                                    <p className="services__modal-info">Implementação de frameworks modernos.
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon">
                                    </i>
                                    <p className="services__modal-info">Testes e correções de bugs.
                                    </p>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>

                <div className="services__content">
                    <div>
                        <i className="uil uil-edit services__icon"></i>
                        <h3 className="services__title">Desenvolvedor <br /> Backend</h3>
                    </div>

                    <span className="services__button" onClick={() => toggleTab(3)}>
                        Ver Mais
                        <i className="uil uil-arrow-right services__button-icon"></i>
                    </span>
                    <div className={toggleState === 3 ? "services__modal active-modal" : "services__modal"}>
                        <div className="services__modal-content">
                            <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>
                            <h3 className="services__modal-title">Backend Developer</h3>
                            <p className="services__modal-description">Base para suas aplicações, focando em segurança e eficiência.
                            </p>

                            <ul className="services__modal-services grid">
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon">
                                    </i>
                                    <p className="services__modal-info">Desenvolvimento de API's.
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon">
                                    </i>
                                    <p className="services__modal-info">Gestão de banco de dados.
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon">
                                    </i>
                                    <p className="services__modal-info">Segurança e autenticação.
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon">
                                    </i>
                                    <p className="services__modal-info">Desenvolvimento de sistemas backend.
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon">
                                    </i>
                                    <p className="services__modal-info">Otimização de performance backend.
                                    </p>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>


            </div>

        </section>
    )
}

export default Services